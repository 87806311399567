import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "../../globalStyles";
import { Footer } from "../../components";
// import Resume from "../../../public/resume.pdf";
import {
  AboutSection,
  AboutRow,
  TextWrapper,
  Heading,
  Subtitle,
} from "./About.elements";

function About({
  primary,
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  imgStart,
}) {
  return (
    <>
      <AboutSection lightBg={lightBg}>
        <Container>
          <AboutRow imgStart={imgStart}>
            <TextWrapper>
              <Heading lightText={lightText}>{headline}</Heading>
              <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
              <Link
                to={process.env.PUBLIC_URL + "/Sayyaf_Resume.pdf"}
                target="_blank"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                <Button big fontBig primary={primary}>
                  {buttonLabel}
                </Button>
              </Link>
            </TextWrapper>
          </AboutRow>
          <Footer />
        </Container>
      </AboutSection>
    </>
  );
}

export default About;
