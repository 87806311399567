import React from "react";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage/Home";
// import ReactGA from "react-ga"; //Google Analytics
// import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
// import { Navbar, Footer, About } from "./components";
function App() {
  // Google Analytics
  // const history = createBrowserHistory();

  // // Initialize google analytics page view tracking
  // history.listen((location) => {
  //   ReactGA.initialize("G-KSHQT1BSDX");
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });
  //history={history}

  return (
    <Router>
      <GlobalStyle />
      {/* <Navbar /> */}
      <Home />
    </Router>
  );
}

export default App;
