import { Link } from "react-router-dom";
import styled from "styled-components";

export const AboutSection = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#101522")};
`;

export const AboutRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  display: "flex";
  alignself: "center";
  justifycontent: "center";
  margin: 0 auto;

  max-width: 700px;
  padding-top: 0;
  padding-bottom: 25px;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 1.1;
  font-weight: 300;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};
`;

export const Subtitle = styled.p`
  text-align: center;
  max-width: 550px;
  margin-bottom: 35px;
  font-size: 22px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

export const Butn = styled(Link)`
  // display: "flex";
  // alignitems: "center";
  // justifycontent: "center";
  text-align: "center";
`;
