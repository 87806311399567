import React from "react";
import { FaTwitter, FaLinkedin, FaGithub } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import {
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialIcons,
  SocialIconLink,
} from "./Footer.elements";

function Footer() {
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialIcons>
            <SocialIconLink
              href="mailto:m.sayyaf01@gmail.com"
              target="_blank"
              aria-label="Email"
            >
              <HiOutlineMail size="44px" />
            </SocialIconLink>
            <SocialIconLink
              href="https://github.com/msayyaf1"
              target="_blank"
              aria-label="Github"
            >
              <FaGithub size="38px" />
            </SocialIconLink>
            <SocialIconLink
              href="https://twitter.com/msayyaf1"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter size="38px" />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.linkedin.com/in/msayyaf/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin size="38px" />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
