import React from "react";
import { dataObj } from "./Data";
import { About } from "../../components";
// import { Footer } from "../../components";
// import styled from "styled-components";

function Home() {
  return (
    <>
      <About {...dataObj} />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
