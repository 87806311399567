export const dataObj = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: "Mohamed Sayyaf",
  description:
    "Hi, I'm a frontend developer from Kerala 🌴, India. I'm passionate about designing, building and implementing novel ideas. I'm a team player and an individual contributor, who enjoys working with teams to build innovative products.",
  buttonLabel: "Checkout My Resume",
  // imgStart: "",
  //   img: require("../../images/svg-1.svg"),
  alt: "Image",
  start: "",
};
