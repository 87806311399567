import React from "react";
import { Nav, NavbarContainer } from "./Navbar.elements";

function Navbar() {
  return (
    <>
      <Nav>
        <NavbarContainer></NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
